<template>
  <div class="sticky top-0 z-10">
    <UserInfo />
    <div class="bg-black text-white text-sm text-center p-1 flex-center gap-5 shadow-sm">
      <button @click="$app.modal.open(FlavorReplacementModal)">
        Add Replacement Flavor
      </button>
      <button @click="$app.modal.open(AddBlenderBottleModal)">
        Add BlenderBottle
      </button>
      <button @click="$app.modal.open(AddSpoonModal)">
        Add Spoon
      </button>
      <button @click="$app.modal.open(AddMerchModal)">
        Add Merch
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const FlavorReplacementModal = () => import('@/components/admin/FlavorReplacementModal.vue')
const AddBlenderBottleModal = () => import('@/components/admin/AddBlenderBottleModal.vue')
const AddSpoonModal = () => import('@/components/admin/AddSpoonModal.vue')
const AddMerchModal = () => import('@/components/admin/AdminAddMerchModal.vue')
</script>
